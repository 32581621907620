














































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateOrgao, dispatchGetOrgaos } from '@/store/orgao/actions';
import { IOrgaoCreate } from '@/interfaces/orgao';

@Component
export default class CadastrarOrgao extends Vue {
  public valid = false;
  public nome: string = '';
  public sigla: string = '';

  public async mounted() {
    await dispatchGetOrgaos(this.$store);
    this.reset();
  }

  public reset() {
    this.nome = '';
    this.sigla = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const novoOrgao: IOrgaoCreate = {
        nome: this.nome,
        sigla: this.sigla,
      };
      const cadastrou = await dispatchCreateOrgao(this.$store, novoOrgao);
      if (cadastrou) {
        this.$router.push('/main/orgaos/listar');
      }
    }
  }
}
